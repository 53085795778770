@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+Glitch&family=Special+Elite&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik Glitch', system-ui;
  font-family: 'Special Elite', system-ui;
}
